export const priceFormat = (value, force) => {
    if (typeof value == 'undefined') {
        return priceFormat(0, force);
    }

    if (typeof value == 'string') {
        value = parseFloat(value);
    }
    
    console.log('force', force);
    if (value == 0 && !force) {
        return 'бесплатно';
    }
  
    const formattedValue = value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  
    return `${formattedValue} ₽`;
};

export const discountPercent = (price, discountPrice) => {
    return Math.round((price - discountPrice) / price * 100);
}